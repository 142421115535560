/**
 * Shared: Utils > Tabbable
 *
 * @copyright 2023 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

import {isElement} from './is';

// -------
// Private
// -------

const TABBABLE_ELEMENT_TAGS = ['button', 'input', 'select', 'textarea', 'a', 'audio', 'video', 'summary'];

/**
 * Bestimmt unter Zuhilfename von Heuristiken, die von
 * https://github.com/focus-trap/tabbable inspiriert sind, ob das angegebene
 * Element per Tab auswählbar ist.
 *
 * @param {HTMLElement} element
 * @returns {boolean}
 */
function isTabbable(element) {
	const tag = element.tagName.toLowerCase();

	// Elements with a -1 tab index are not tabbable
	if (element.getAttribute('tabindex') === '-1') {
		return false;
	}

	// Elements with a disabled attribute are not tabbable
	if (element.hasAttribute('disabled')) {
		return false;
	}

	// Elements with aria-disabled are not tabbable
	if (
		element.hasAttribute('aria-disabled') &&
		element.getAttribute('aria-disabled') !== 'false'
	) {
		return false;
	}

	// Radios without a checked attribute are not tabbable
	if (
		tag === 'input' &&
		element.getAttribute('type') === 'radio' &&
		!element.hasAttribute('checked')
	) {
		return false;
	}

	// Elements that are hidden have no offsetParent and are not tabbable
	if (element.offsetParent === null) {
		return false;
	}

	// Elements without visibility are not tabbable
	if (window.getComputedStyle(element).visibility === 'hidden') {
		return false;
	}

	// Audio and video elements with the controls attribute are tabbable
	if (
		(tag === 'audio' || tag === 'video') &&
		element.hasAttribute('controls')
	) {
		return true;
	}

	// Elements with a tabindex other than -1 are tabbable
	if (element.hasAttribute('tabindex')) {
		return true;
	}

	// Elements with a contenteditable attribute are tabbable
	if (
		element.hasAttribute('contenteditable') &&
		element.getAttribute('contenteditable') !== 'false'
	) {
		return true;
	}

	return TABBABLE_ELEMENT_TAGS.includes(tag);
}

// -------
// Public
// -------

/**
 * Das erste und letzte per Tab-Taste auswählbare Element zurückgeben.
 *
 * @param {HTMLElement, ShadowRoot} root
 * @returns {{start: HTMLElement, end: HTMLElement}}
 */
const getTabbableBoundary = (root) => {
	const tabbableElements = getTabbableElements(root);

	// Find the first and last tabbable elements
	const start = tabbableElements[0] ?? null;
	const end = tabbableElements[tabbableElements.length - 1] ?? null;

	return {start, end};
};

/**
 * Alle per Tab-Taste auswählbaren Elemente zusammenstellen.
 *
 * @param root
 * @returns {Array}
 */
const getTabbableElements = (root) => {
	const tabbableElements = [];

	function walk(element) {
		if (isElement(element)) {
			// if the element has "inert" we can just no-op it.
			if (element.hasAttribute('inert')) {
				return;
			}

			if (!tabbableElements.includes(element) && isTabbable(element)) {
				tabbableElements.push(element);
			}

			if (element.shadowRoot !== null && element.shadowRoot.mode === 'open') {
				walk(element.shadowRoot);
			}

			if (element.children) {
				for (const child of element.children) {
					walk(child);
				}
			}
		}
	}

	// Collect all elements including the root
	walk(root);

	// Is this worth having? Most sorts will always add increased overhead. And positive tabindexes shouldn't really be used.
	// So is it worth being right? Or fast?
	return tabbableElements.sort((a, b) => {
		// Make sure we sort by tabindex.
		const aTabindex = Number(a.getAttribute('tabindex')) || 0;
		const bTabindex = Number(b.getAttribute('tabindex')) || 0;

		return bTabindex - aTabindex;
	});
};

// Export
export {
	getTabbableBoundary,
	getTabbableElements
};

