/**
 * Shared: Components > Slider
 *
 * Requires ´Swiper´ (https://swiperjs.com/).
 *
 * @copyright 2023 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

import {
	noop,
	extend
} from '../../utils/index';
import {
	isElement,
	isString
} from '../../utils/is';

import SelectorEngine from '../../dom/selector-engine';
import Data           from '../../dom/data';
import EventHandler   from '../../dom/event-handler';

// -------
// Private
// -------

const NAME      = 'slider';
const DATA_KEY  = `ifab.${NAME}`;
const EVENT_KEY = `.${DATA_KEY}`;
const API_KEY   = `.data-api`;

const EVENT_INIT           = `init${EVENT_KEY}`;
const EVENT_ENTER_VIEWPORT = `enter-viewport${EVENT_KEY}`;
const EVENT_LEAVE_VIEWPORT = `leave-viewport${EVENT_KEY}`;

const DEFAULTS = {
	a11y               : {
		paginationBulletMessage: 'go to slide {{index}}',
		prevSlideMessage       : 'previous slide',
		nextSlideMessage       : 'next slide'
	},
	autoHeight         : true,
	init               : true,
	lazy               : {
		elementClass: 'slider-lazyload'
	},
	loop               : false,
	pagination         : {
		clickable: true
	},
	slidesPerView      : 1,
	spaceBetween       : 0,
	speed              : 400,
	watchSlidesProgress: true
};

const observerInView = new IntersectionObserver((entries, observer) => {
	for (const element of entries) {
		const target = element.target;
		const api    = Data.get(target, `${DATA_KEY}${API_KEY}`);

		if (element.isIntersecting) {
			if (api) {
				EventHandler.trigger(target, EVENT_ENTER_VIEWPORT);
			} else {
				EventHandler.trigger(target, EVENT_INIT);
			}
		} else {
			EventHandler.trigger(target, EVENT_LEAVE_VIEWPORT);
		}
	}
});

/**
 * Prüfung auf Abhängigkeiten (3rd-Party)
 *
 * @type {boolean}
 */
window.depCheckSlider = (() => {
	let flag = (typeof Swiper !== 'undefined');

	if (!flag && processEnv === 'development') {
		// eslint-disable-next-line no-console
		console.error(`Unfortunately, the current environment still requires Swiper`);
	}

	return flag;
})();

/**
 * Steuerelemente des Sliders holen und als Einstellungsobjekt zurückgeben.
 * Dies ist erforderlich, weil der strukturelle Slideraufbau sich bzgl. der
 * Steuerelemente vom Original unterscheidet!
 *
 * @param {HTMLElement} element
 * @returns {Object}
 */
const detectSliderControls = (element) => {
	const pagination = SelectorEngine.findOne('.swiper-pagination', element);
	const btnNext    = SelectorEngine.findOne('.swiper-button-next', element);
	const btnPrev    = SelectorEngine.findOne('.swiper-button-prev', element);

	return {
		navigation: {
			nextEl: btnNext || null,
			prevEl: btnPrev || null
		},
		pagination: {
			el: pagination || null
		}
	};
};

/**
 * Eine bestehende Sliderinstanz "reinitialisieren".
 *
 * @param {Object} api    - aktuelle Sliderinstanz (API)
 * @param {Object} [o={}] - zu aktualisierende Optionen
 * @returns {Object}
 */
const rebuild = (api, o = {}) => {
	const curElement = api.el;
	const curParams  = api.originalParams;
	// const curIndex   = api.realIndex || 0;
	const newParams = extend({}, curParams, {
		init: true// ,
		// on  : {
		// 	afterInit: function() {
		// 		this.slideTo(curIndex);
		// 	}
		// }
	}, o);

	return new Swiper(curElement, newParams);
};

/**
 * Rendering eines Sliders.
 *
 * @param {HTMLElement} element
 * @param {Object} o
 */
const render = (element, o = {}) => {
	const elSwiper = SelectorEngine.findOne('.swiper', element);

	let api = null;

	if(elSwiper) {
		// Aktuelle Slider-Instanz.
		api = new Swiper(elSwiper, o);

		// Überwachung per Resize-Observer triggert Slider-Update.
		// const resizeObserver = new ResizeObserver(() => {
		// 	api.update();
		// });

		// resizeObserver.observe(element);
	}

	// API-Instanz in aktuellem Element speichern.
	Data.set(element, `${DATA_KEY}${API_KEY}`, api);

	// Initialisierungsstatus setzen.
	if (o.init) {
		Data.set(element, `${DATA_KEY}.initialized`, true);
	}

	return element;
};

/**
 * Einen einzelnen Slider initialisieren.
 *
 * @param {HTMLElement} element
 * @param {Object} [o={}] - Optionen des Sliders.
 * @constructor
 */
const preRender = (element, o = {}) => {
	if (!Data.get(element, `${DATA_KEY}.initialized`)) {
		const sliderControls = detectSliderControls(element);
		const _o             = extend({}, DEFAULTS, sliderControls, o);

		// Eine Initialisierung gibt es nur einmal.
		EventHandler.one(element, EVENT_INIT, function() {
			render(element, _o);
		});

		// Erscheinen im Viewport.
		EventHandler.on(element, EVENT_ENTER_VIEWPORT, function() {
			const api = Data.get(element, `${DATA_KEY}${API_KEY}`);

			if (api && api.originalParams.autoplay.enabled) {
				api.autoplay.start();
			}
		});

		// Verlassen des Viewport.
		EventHandler.on(element, EVENT_LEAVE_VIEWPORT, function() {
			const api = Data.get(element, `${DATA_KEY}${API_KEY}`);

			if (api && api.autoplay && api.autoplay.running) {
				api.autoplay.stop();
			}
		});

		// ´Starten´ der Komponente wird per Viewport-Observer gesteuert!
		observerInView.observe(element);
	}

	return element;
};

// -------
// Public
// -------

/**
 * Alle verfügbaren Slider (Attribut `data-slider`) initialisieren.
 *
 * @param {HTMLElement|String|null} [m=null]
 * @param {Object} [o={}]
 * @returns {HTMLElement|Array}
 */
const init = (m = null, o = {}) => {
	const _o = extend({}, DEFAULTS, o);

	let group;

	if (isElement(m)) {
		group = preRender(m, _o);
	} else {
		const collection = SelectorEngine.find(
			(isString(m)) ? m : `[data-c="${NAME}"]`,
			_o.container || document.documentElement
		);

		group = [];

		for (const element of collection) {
			group.push(preRender(element, _o));
		}
	}

	return group;
};

// Export
export default {
	init   : (window.depCheckSlider ? init : noop),
	rebuild: (window.depCheckSlider ? rebuild : noop)
};
