/**
 * Vendors ´Cookie consent´
 */

import {
	lockBodyScrolling,
	unlockBodyScrolling
} from '../../../shared/utils/scroll';

import SelectorEngine from '../../../shared/dom/selector-engine';
import EventHandler from '../../../shared/dom/event-handler';

import Matomo from './tools/matomo';
import {loadJavascript} from "../../../shared/utils/load";

// -------
// Private
// -------

// const MatomoInstance = null;
const MatomoInstance = new Matomo(null, {
	// setCookieDomain : '*.relaunch.landkreis-nordsachsen.de',
	siteId: (window.pageInstance.settings.statistic.matomo === undefined) ? 0 : window.pageInstance.settings.statistic.matomo.id,
	trackerDomain: (window.pageInstance.settings.statistic.matomo === undefined) ? '' : window.pageInstance.settings.statistic.matomo.library
	//onEnable     : () => {},
	//onDisable    : () => {}
});

// const GtmInstance = null;
// const GaInstance = null;

// ------
// Public
// ------

const init = () => {
	let inst = null;

	if (typeof ScCookie !== 'undefined') {
		inst = new ScCookie({
			on: {
				showPreferences: function () {
					lockBodyScrolling(this);
				},
				hidePreferences: function () {
					unlockBodyScrolling(this);
				},
				saveConsent: function (consent) {
					document.location.reload();
				},
				init: function (consent) {
					// Youtube?
					if (this.getConsent('youtube')) {
						const collection = SelectorEngine.find('[data-player-type="youtube"]');

						for (const element of collection) {
							EventHandler.trigger(element, 'disable.privacyMode');
						}
					}

					// Vimeo?
					if (this.getConsent('vimeo')) {
						const collection = SelectorEngine.find('[data-player-type="vimeo"]');

						for (const element of collection) {
							EventHandler.trigger(element, 'disable.privacyMode');
						}
					}

					// Googlemaps?
					if (this.getConsent('googlemaps')) {
						const collection = SelectorEngine.find('[data-gmap]');

						for (const element of collection) {
							EventHandler.trigger(element, 'disable.privacyMode');
						}
					}

					// Matomo?
					if (MatomoInstance && MatomoInstance.isReady) {
						if (this.getConsent('matomo')) {
							MatomoInstance.enable();
						} else {
							MatomoInstance.disable();
						}
					}


					// Google Analytics?
					if (this.getConsent('googleanalytics')) {
						if(!Object.keys(window.pageInstance.settings.statistic).length) {
							return;
						}

						if(!window.pageInstance.settings.statistic.ga.library || !window.pageInstance.settings.statistic.ga.id) {
							return;
						}

						const gaLibrary = window.pageInstance.settings.statistic.ga.library;
						const gaId      = window.pageInstance.settings.statistic.ga.id;
						// const domain    = window.pageInstance.settings.paths.domain ? window.pageInstance.settings.paths.domain : '';
						const srcGtm    = gaLibrary + gaId;

						loadJavascript(srcGtm, {
							'async': true,
							'defer': true,
							'id'   : 'googleanalytics'
						});


						setTimeout(function() {
							window.dataLayer = window.dataLayer || [];
							function gtag(){dataLayer.push(arguments);}
							gtag('js', new Date());
							gtag('config', gaId);
						}, 100);
					} else {
						// document.cookie = '_ga' + '=;expires=Mon Jan 01 1900 00:00:00 ; path=/ ; domain=' + domain;
						// document.cookie = '_ga' + '=;expires=Mon Jan 01 1900 00:00:00 ; path=/ ; domain=.example-cookie-domain.com';
					}
				}
			}
		});
	}

	// Video: Aktivieren und abspielen
	// EventHandler.on(document.body, 'click', '[data-privacy-enable]', (ev) => {
	// 	console.log(Manipulator.getDataAttribute(ev.delegateTarget, 'privacy-enable'));
	// });

	// Custom-Trigger für Cookie-Einstellungen anzeigen.
	EventHandler.on(document.body, 'click', '[data-privacy-settings]', (event) => {
		event.stopPropagation();

		window.ifabCookieConsent.togglePreferences();
	});

	return window.ifabCookieConsent = inst;
};

// Export
export default init;
