/**
 * Shared: Utils > Focustrap visible
 *
 * Adaptierung von ´Bootstrap util/focustrap.js´ (https://github.com/twbs/bootstrap/blob/main/js/src/util/focustrap.js).
 */

import {getTabbableElements} from './tabbable';

import EventHandler from '../dom/event-handler.js';
import SelectorEngine from '../dom/selector-engine.js';
import BaseClass from "./base-class";

/**
 * Constants
 */

/**
 * @type {string}
 */
const NAME = 'focustrap';

/**
 * @type {string}
 */
const VERSION = '1.0.0';

const DATA_KEY          = `ifab.${NAME}`;
const EVENT_KEY         = `.${DATA_KEY}`;
const EVENT_FOCUSIN     = `focusin${EVENT_KEY}`;
const EVENT_KEYDOWN_TAB = `keydown.tab${EVENT_KEY}`;

const TAB_KEY = 'Tab';
const TAB_NAV_FORWARD = 'forward';
const TAB_NAV_BACKWARD = 'backward';

/**
 *
 * @type {Object}
 */
const DEFAULT = {
	autofocus  : true,
	trapElement: null // The element to trap focus inside of
};

/**
 * Class definition
 */

class FocusTrap extends BaseClass {
	constructor(element, config) {
		super(element, config);

		if (!element) {
			return;
		}

		this._isActive            = false;
		this._lastTabNavDirection = null;
		this._tabbedElement       = null;
	}

	// Public ----------------------------------------------------------------------------------------------------------

	activate() {
		if (this._isActive) {
			return;
		}

		if (this._config.autofocus) {
			this._tabbedElement = this._element;

			this._tabbedElement.focus();
		}

		EventHandler.off(document, EVENT_KEY); // guard against infinite focus loop
		EventHandler.on(document, EVENT_FOCUSIN, event => this._handleFocusin(event));
		EventHandler.on(document, EVENT_KEYDOWN_TAB, event => this._handleKeydown(event));

		this._isActive = true;
	}

	deactivate() {
		if (!this._isActive) {
			return;
		}

		this._isActive = false;

		EventHandler.off(document, EVENT_KEY);
	}

	// Private ---------------------------------------------------------------------------------------------------------

	_handleFocusin(event) {
		const trapElement = this._element;

		if (event.target === document || event.target === trapElement || trapElement.contains(event.target)) {
			return;
		}

		// const elements = SelectorEngine.focusableChildren(trapElement);
		const elements = getTabbableElements(trapElement);

		if (elements.length === 0) {
			this._tabbedElement = trapElement;
		} else if (this._lastTabNavDirection === TAB_NAV_BACKWARD) {
			this._tabbedElement = elements[elements.length - 1];
		} else {
			this._tabbedElement = elements[0];
		}

		if(this._tabbedElement) {
			this._tabbedElement.focus();
		}
	}

	_handleKeydown(event) {
		if (event.key !== TAB_KEY) {
			return;
		}

		this._lastTabNavDirection = event.shiftKey ? TAB_NAV_BACKWARD : TAB_NAV_FORWARD;
	}

	// Static ----------------------------------------------------------------------------------------------------------

	// Getters ---------------------------------------------------------------------------------------------------------

	/**
	 * @returns {string}
	 * @constructor
	 */
	static get VERSION() {
		return VERSION;
	}

	/**
	 * @returns {Object}
	 * @constructor
	 */
	static get Default() {
		return DEFAULT;
	}

	/**
	 * @returns {string}
	 * @constructor
	 */
	static get NAME() {
		return NAME;
	}

	/**
	 * @returns {string}
	 * @constructor
	 */
	static get DATA_KEY() {
		return `ifab.${this.NAME}`;
	}

	/**
	 * @returns {string}
	 * @constructor
	 */
	static get EVENT_KEY() {
		return `.${this.DATA_KEY}`;
	}
}

// Export
export default FocusTrap;
