/**
 * Shared: Utils > Base class
 *
 * @copyright 2023 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

import {
	executeAfterTransition,
	extend
} from './index';

import Data from '../dom/data';

class BaseClass {
	/**
	 * @param {HTMLElement|Node} [element=null]
	 * @param {Object} [config={}]
	 */
	constructor(element = null, config = {}) {
		this._element = element;
		this._config  = this._getConfig(config);
		this._config  = this._getConfig(config);

		if (this._element) {
			Data.set(this._element, this.constructor.DATA_KEY, this);
		}
	}

	// Private -----------------------------------------------------------------

	/**
	 * @param {Function} callback
	 * @param {HTMLElement|Node} element
	 * @param {Boolean} [isAnimated=true]
	 * @private
	 */
	_queueCallback(callback, element, isAnimated = true) {
		executeAfterTransition(callback, element, isAnimated);
	}

	/**
	 * @param {Object} config
	 * @returns {Object}
	 * @private
	 */
	_getConfig(config) {
		config = this._mergeConfigObj(config, this._element);
		config = this._configAfterMerge(config);

		return config;
	}

	/**
	 * @param {Object} config
	 * @returns {Object}
	 * @private
	 */
	_configAfterMerge(config) {
		return config;
	}

	/**
	 * @param {Object} config
	 * @param {HTMLElement|Node} element
	 * @returns {Object}
	 * @private
	 */
	_mergeConfigObj(config, element) {
		// const jsonConfig = isElement(element) ? Manipulator.getDataAttribute(element, 'config') : {};

		return extend(
			{},
			this.constructor.Default,
			// (typeof jsonConfig === 'object' ? jsonConfig : {}),
			(typeof config === 'object' ? config : {})
		);

		// return {
		// 	...this.constructor.Default,
		// 	...(typeof jsonConfig === 'object' ? jsonConfig : {}),
		// 	...(isElement(element) ? Manipulator.getDataAttributes(element) : {}),
		// 	...(typeof config === 'object' ? config : {})
		// };
	}

	// Static ------------------------------------------------------------------

	/**
	 * @param element
	 * @returns {string}
	 */
	static getInstance(element) {
		return Data.get(element, this.DATA_KEY);
	}

	/**
	 *
	 * @param element
	 * @param {Object} [config={}]
	 * @returns {BaseClass}
	 */
	static getOrCreateInstance(element, config = {}) {
		return this.getInstance(element) || new this(element, typeof config === 'object' ? config : null);
	}

	// Getters -----------------------------------------------------------------

	/**
	 * @returns {string}
	 * @constructor
	 */
	static get VERSION() {
		return '';
	}

	/**
	 * @returns Object
	 * @constructor
	 */
	static get Default() {
		return {};
	}

	/**
	 * @constructor
	 */
	static get NAME() {
		throw new Error('You have to implement the static method "NAME" for each class!');
	}
}

export default BaseClass;
