/**
 * Shared: Utils > Load
 *
 * @copyright 2023 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

import {
	noop,
	extend
} from './index';

// ------
// Privat
// ------

const KEY_REGEX = /[ "#$%&'()*+,./:<>?\\{}~]/g;

const loaded = {
	javascripts: new Map(),
	styles     : new Map()
};

// ------
// Public
// ------

const unload = (type, s, callback) => {
	const key = s.replace(KEY_REGEX, '');

	let source;

	if (loaded[type].has(s)) {
		source = loaded[type].get(s);
	} else if(loaded[type].has(key)) {
		source = loaded[type].get(key);
	}

	if (source) {
		source.remove();
		callback();
	}
};

/**
 * Script aus Dom entfernen
 *
 * @param {string} s
 * @param {Function} callback
 */
const unloadJavascript = (s, callback = noop) => {
	unload('javascript', s, callback);
};

/**
 * Script im Dom integrieren
 *
 * @param {string} src
 * @param {Object} o
 */
const loadJavascript = (src, o = {}) => {
	if (src) {
		const _o = extend({}, {
			async   : false,
			callback: noop,
			defer   : false,
			id      : '',
			target  : null
		}, o);

		const key = _o.id || src.replace(KEY_REGEX, '');

		let script = (loaded.javascripts.has(key)) ? loaded.javascripts.get(key) : null;

		if (script) {
			// Script ist schon integriert. Führe Callback aus.
			script.options.callback();
		} else {
			// Script integrieren.

			script = document.createElement('script');

			if (_o.id) {
				script.id = _o.id;
			}

			if (_o.async) {
				script.async = true;
			}

			if (_o.defer) {
				script.defer = true;
			}

			script.src = src;

			// Script speichern.
			loaded.javascripts.set(key, {
				options: _o,
				element: script
			});

			const target = _o.target || document.body;

			target.append(script);

			script.addEventListener('load', () => {
				_o.callback();
			});
		}
	}
};

const loadStyle = (src, o = {}) => {
	if (src) {
		const _o = extend({}, {
			callback: noop,
			id      : '',
			target  : null
		}, o);

		const key = _o.id || src.replace(KEY_REGEX, '');

		let style = (loaded.styles.has(key)) ? loaded.styles.get(key) : null;

		if (style) {
			// Style ist schon integriert. Führe Callback aus.
			// style.options.callback();
		} else {
			// Style integrieren.

			style = document.createElement('link');

			style.rel  = 'stylesheet';
			style.type = 'text/css';
			style.href = src;

			const target = _o.target || document.head;

			target.append(style);
		}
	}
};

const unloadStyle = (s, callback = noop) => {
	unload('style', s, callback);
};

// Export
export {
	loadJavascript,
	unloadJavascript,
	loadStyle,
	unloadStyle
};
