/**
 * Shared: Importdatei
 *
 * @copyright 2023 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

import Components from './components';

export default () => {
	Components();
};
