/**
 * Shared: Dom > Data
 *
 * @copyright 2023 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

// -------
// Private
// -------

window.sharedElementMap = window.sharedElementMap || new Map();

// ------
// Public
// ------

const set = (element, key, instance) => {
	if (!window.sharedElementMap.has(element)) {
		window.sharedElementMap.set(element, new Map());
	}

	const instanceMap = window.sharedElementMap.get(element);

	// if (!instanceMap.has(key) && instanceMap.size !== 0) {
	// 	if (processEnv === 'development') {
	// 		// eslint-disable-next-line no-console
	// 		console.error(`No more than one instance per element is permitted. Bound instance: ${Array.from(instanceMap.keys())[0]}.`);
	// 	}
	// 	return;
	// }

	instanceMap.set(key, instance);
};

const get = (element, key) => {
	if (window.sharedElementMap.has(element)) {
		const v = window.sharedElementMap.get(element).get(key);

		return v === undefined ? null : v;
	}

	return null;
};

const remove = (element, key) => {
	if (!window.sharedElementMap.has(element)) {
		return;
	}

	const instanceMap = window.sharedElementMap.get(element);

	instanceMap.delete(key);

	// Elementreferenzen freigeben, wenn für ein Element keine Instanzen mehr
	// vorhanden sind.
	if (instanceMap.size === 0) {
		window.sharedElementMap.delete(element);
	}
};

// Export
export default {
	set,
	get,
	remove
};
