/**
 * Navigation
 */

import {
	lockBodyScrolling,
	unlockBodyScrolling
} from '../../../shared/utils/scroll';

import SelectorEngine from '../../../shared/dom/selector-engine';
import Manipulator from '../../../shared/dom/manipulator';
import EventHandler from '../../../shared/dom/event-handler';

import Nav from '../../../shared/components/nav/nav';
import Drawer from '../../../shared/components/drawer/drawer';

// -------
// Private
// -------

const NAME      = 'menu';
const DATA_KEY  = `ifab.${NAME}`;
const EVENT_KEY = `.${DATA_KEY}`;
// const API_KEY    = '.data-api';

const pageMenu = SelectorEngine.findOne('#page-menu-drawer');

let mainNavInstance;

/**
 * Drawer (Navigationsoverlay)
 */
const setupDrawer = () => {
	const sentinelTarget = document.documentElement;
	const trigger = SelectorEngine.findOne('[aria-controls="page-menu-drawer"]');
	const drawer  = new Drawer(pageMenu, {
		placement: 'end',
		// onInit   : (event) => {
		// 	const componentCloseButton = SelectorEngine.findOne('.drawer__header > .drawer-close', event.target);
		//
		// 	if (componentCloseButton) {
		// 		componentCloseButton.remove();
		// 	}
		// },
		onShow   : function() {
			Manipulator.addClass(trigger, '-active');
			Manipulator.addClass(sentinelTarget, 'page-menu-is-active');
		},
		onHidden : () => {
			Manipulator.removeClass(trigger, '-active');
			Manipulator.removeClass(sentinelTarget, 'page-menu-is-active');
		}
	});

	EventHandler.on(trigger, 'click.ifab.pageMenuDrawer', (event) => {
		event.preventDefault();
		event.stopPropagation();

		if (event.delegateTarget.classList.contains('-active')) {
			drawer.hide();
		} else {
			drawer.show();
		}
	});
};

/**
 * Initialisierung der eigentlichen Navigationsliste.
 */
const navigation = () => {
	const nav = SelectorEngine.findOne('#main-nav');

	if (nav) {
		mainNavInstance = new Nav(nav, {
			closeByOutsideClick: false,
			closeByEsc         : false,
			labelSubmenu       : (window.pageInstance.tm) ? window.pageInstance.tm.translate('ariaLabelSubmenu') : 'Submenu for %s',
			labelSubmenuTrigger: (window.pageInstance.tm) ? window.pageInstance.tm.translate('ariaLabelSubmenuTrigger') : 'Show submenu for %s',
			useReveal          : true, // { visibleDisplay: 'flex' },
			onHide             : () => {
				// Manipulator.addClass(event.relatedMenu, '-will-hide');
			},
			onHidden           : () => {
				// Manipulator.removeClass(event.relatedMenu, '-will-hide');
			},
			onShow             : () => {
				// Manipulator.addClass(event.relatedMenu, '-will-show');
			},
			onShown            : () => {
				// Manipulator.removeClass(event.relatedMenu, '-will-show');
			}
		});

		mainNavInstance.showCurrentTree();

		// const curPageItem = SelectorEngine.findOne('[aria-current="page"]');
		//
		// if (appMenuBody && curPageItem) {
		// 	scrollElementIntoView(curPageItem, appMenuBody, 'vertical');
		// }
	}
};

// -------
// Public
// -------

const menu = () => {
	if (pageMenu) {
		setupDrawer();
		// navigation();
	}
};

// Export
export default menu;
