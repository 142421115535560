/**
 * Shared: Components > Scroll progress
 *
 * @copyright 2023 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

import {
	noop,
	extend
} from '../../utils/index';

import Manipulator  from '../../dom/manipulator';
import Data         from '../../dom/data';
import EventHandler from '../../dom/event-handler';

// -------
// Private
// -------

const NAME      = 'scroll-progress';
const DATA_KEY  = `ifab.${NAME}`;
const EVENT_KEY = `.${DATA_KEY}`;
// const API_KEY   = `.data-api`;

const EVENT_UPDATE = `update${EVENT_KEY}`;

const DEFAULTS = {
	onUpdate : noop
	// onUpdate : function(event, percent) {
	// 	console.log(percent);
	// }
};

let element = null;

/**
 * Berechnung des Prozentwertes.
 */
const update = () => {
	// how much the user has scrolled by
	const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
	const winHeight = window.innerHeight;
	const docHeight = Math.max(
		document.body.scrollHeight, document.documentElement.scrollHeight,
		document.body.offsetHeight, document.documentElement.offsetHeight,
		document.body.clientHeight, document.documentElement.clientHeight
	);
	const p         = Math.floor(winScroll / (docHeight - winHeight) * 100);

	element.style.transform = 'scaleX(' + (p / 100) + ')';

	EventHandler.trigger(element, EVENT_UPDATE, {
		percent : p
	});
};

// -------
// Public
// -------

/**
 * @param {Object} o
 */
const render = (o = {}) => {
	if (!Data.get(document.body, `${DATA_KEY}.added`)) {
		const _o = extend({}, DEFAULTS, o);

		const container = Manipulator.createElementFrom(`<div class="${NAME}"/>`);

		element = Manipulator.createElementFrom(`<span/>`);

		// Elemente dem Dokument hinzufügen.
		Manipulator.elementAppend(element, container);
		Manipulator.elementAppend(container, document.body);

		// Update-Event des Elementes
		EventHandler.on(element, EVENT_UPDATE, (event) => {
			_o.onUpdate(event, event.percent);
		});

		// Document-Scroll-Event
		document.addEventListener('scroll', function() {
			requestAnimationFrame(update);
		});

		// Window-Resize-Event
		window.addEventListener('resize', function() {
			requestAnimationFrame(update);
		});

		Data.set(document.body, `${DATA_KEY}.added`, true);
	}
};

// Export
export default {
	init: render
};
