/**
 * Sources / Default / Components / Overwrites / Shared > Table
 *
 * @copyright 2024 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

import {extend} from '../../../../../shared/utils';
import {
	isElement,
	isString
} from '../../../../../shared/utils/is';

import SelectorEngine from '../../../../../shared/dom/selector-engine';
import Data           from '../../../../../shared/dom/data';
import Manipulator    from '../../../../../shared/dom/manipulator';

// -------
// Private
// -------

const NAME     = 'table';
const DATA_KEY = `ifab.${NAME}`;
// const EVENT_KEY  = `.${DATA_KEY}`;
// const API_KEY    = '.data-api';

/**
 * ´Button´-Element initialisieren.
 *
 * @param {HTMLElement|String|null} [m='table']
 * @returns {HTMLElement}
 */
const tableScrollable = (m = 'table') => {
	const collection = (isElement(m)) ? [m] : SelectorEngine.find(m);

	for (const element of collection) {
		if (!Data.get(element, `${DATA_KEY}.initialized`)) {
			let figureWrap = SelectorEngine.parents(element, 'figure')[0];

			if (figureWrap) {
				Manipulator.addClass(element, 'table');
			} else {
				// `<figure/>` gernerieren und nach der Tabelle einfügen.
				figureWrap = Manipulator.elementAfter('<figure class="table-container"/>', element);

				// Tabelle in `<figure/>` verschieben.
				Manipulator.elementAppend(figureWrap, element);
			}

			Manipulator.removeClass(figureWrap, 'table');
			Manipulator.addClass(figureWrap, 'table-container');

			figureWrap.style.overflowX = 'auto';

			// Initialisierungsstatus setzen.
			Data.set(element, `${DATA_KEY}.initialized`, true);
		}
	}
};

// ------
// Public
// ------

// Export
export default {
	makeScrollable: tableScrollable
};
