/**
 * Loader
 */

import {
	lockBodyScrolling,
	unlockBodyScrolling
} from '../../../shared/utils/scroll';

import SelectorEngine from '../../../shared/dom/selector-engine';
import Manipulator    from '../../../shared/dom/manipulator';

// -------
// Privat
// -------

// -------
// Public
// -------

const loader = () => {
	const pageLoader = SelectorEngine.findOne('.page-loader');

	if(!pageLoader) {
		return false;
	}

	lockBodyScrolling(pageLoader);

	window.addEventListener("load", (event) => {
		setTimeout(function() {
			Manipulator.addClass(pageLoader, '-hide');

			unlockBodyScrolling(pageLoader);
		}, 100);
	});
};

// Export
export default loader;