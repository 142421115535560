/**
 * Shared: Components > Gmap
 *
 * @copyright 2023 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

import {
	isElement,
	isString
}               from '../../utils/is';
import {extend} from '../../utils/index';


import SelectorEngine from '../../dom/selector-engine';
import Manipulator    from '../../dom/manipulator';
import Data           from '../../dom/data';
import EventHandler   from '../../dom/event-handler';

import GoogleMapsLoader from './google-maps-loader';
import GoogleMaps       from './google-maps';

// -------
// Private
// -------

const NAME       = 'gmap';
const DATA_KEY   = `ifab.${NAME}`;
const EVENT_KEY  = `.${DATA_KEY}`;
const API_KEY    = `.data-api`;

const DEFAULTS = {
	apiKey  : '',
	settings: {}
};

const observerInView = new IntersectionObserver((entries) => {
	for (const entry of entries) {
		const element = entry.target;

		if (entry.isIntersecting) {
			EventHandler.trigger(element, `init${EVENT_KEY}`);
		}
	}
}, {
	root     : null,
	threshold: [0.25]
});

/**
 * Einzelne Gmap initialisieren.
 *
 * @param {HTMLElement} element
 * @param {Object} o - Videooptionen
 * @returns {HTMLElement}
 */
const render = (element, o) => {
	const isPrivacy = Manipulator.getDataAttribute(element, 'privacy');

	Data.set(element, `${DATA_KEY}.initialized`, false);
	Data.set(element, `${DATA_KEY}.privacy`, isPrivacy);

	// Event ´privacy´ (element)
	EventHandler.on(element, 'disable.privacyMode', () => {
		Manipulator.removeDataAttribute(element, 'privacy');

		Data.set(element, `${DATA_KEY}.privacy`, false);

		EventHandler.off(element, 'disable.privacyMode');
	});

	// Event ´init´ (element)
	EventHandler.on(element, `init${EVENT_KEY}`, () => {
		if (!Data.get(element, `${DATA_KEY}.privacy`)) {
			// ´Google Maps´ laden
			const GmapL = new GoogleMapsLoader(o.apiKey);

			// console.log(markerClusterer.MarkerClusterer);
			GmapL
				.load()
				.then(() => {
					const api = new GoogleMaps(element, o.settings);

					Data.set(element, `${DATA_KEY}${API_KEY}`, api);
					Data.set(element, `${DATA_KEY}.initialized`, true);

					Manipulator.addClass(element, '-initialized');
				});

			EventHandler.off(element, `init${EVENT_KEY}`);
		}
	});

	observerInView.observe(element);
};

// -------
// Public
// -------

/**
 * ´Google maps´-Elemente zusammenstellen und initialisieren.
 *
 * @param {HTMLElement|String|null} [m=null]
 * @param {Object} [o={}]
 * @returns {HTMLElement|Array}
 */
const init = (m = null, o = {}) => {
	const _o = extend({}, DEFAULTS, o);

	let group;

	if (isElement(m)) {
		group = render(m, _o);
	} else {
		const collection = SelectorEngine.find((isString(m)) ? m : `[data-${NAME}]`, document.documentElement);

		group = [];

		for (const element of collection) {
			group.push(render(element, _o));
		}
	}

	return group;
};

// Export
export default {
	init: init
};
