/**
 * Shared: Components > Player
 *
 * Requires ´Plyr´ (https://plyr.io/).
 *
 * @copyright 2023 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

import {
	noop,
	extend
} from '../../utils/index';
import {isElement} from '../../utils/is';

import SelectorEngine from '../../dom/selector-engine';
import Manipulator    from '../../dom/manipulator';
import Data           from '../../dom/data';
import EventHandler   from '../../dom/event-handler';

// -------
// Private
// -------

const NAME      = 'player';
const DATA_KEY  = `ifab.${NAME}`;
const EVENT_KEY = `.${DATA_KEY}`;
const API_KEY   = `.data-api`;

const DEFAULTS = {
	audio: {
		autoplay  : false,
		resetOnEnd: true,
		controls  : ['play', 'progress', 'current-time']
	},
	video: {
		autoplay  : true,
		controls  : ['play', 'progress', 'current-time', 'fullscreen'],
		fullscreen: {
			iosNative: true
		},
		// loop      : {
		// 	active: (typeof $container.data('loop') !== 'undefined')
		// },
		playsinline: true,
		resetOnEnd : true,
		settings   : [],
		loop       : 1,
		vimeo      : {
			playsinline: true
		},
		youtube    : {
			autoplay      : 1,
			loop          : 1,
			noCookie      : true,
			rel           : 0,
			showinfo      : 0,
			iv_load_policy: 3,
			modestbranding: 1
		}
	}
};

const observerInView = new IntersectionObserver((entries, observer) => {
	for (const entry of entries) {
		const element = entry.target;

		// if (entry.isIntersecting) {
		// 	// Starte den Player nur wenn er auch der zuletzt abgespielte Player
		// 	// war.
		// 	// TODO: Starte Player wenn er zuletzt schon abgespielt war
		// 	if (
		// 		window.activePlayer &&
		// 		Data.get(window.activePlayer, 'uid') === Data.get(element, 'uid')
		// 	) {
		// 		EventHandler.trigger(element, `play${EVENT_KEY}`);
		// 	}
		//
		// 	// if($el.lastPlayed) {
		// 	// 	$(el.target).trigger(EVENT.PLAY + '.player')
		// 	// }
		// } else {
		// 	// Player anhalten.
		// 	EventHandler.trigger(element, `stop${EVENT_KEY}`);
		// }
	}
}, {
	root     : null,
	threshold: [0.25]
});

/**
 * Prüfung auf Abhängigkeiten (3rd-Party)
 *
 * @type {boolean}
 */
window.depCheckPlayer = (() => {
	let flag = (typeof Plyr !== 'undefined');

	if (!flag && processEnv === 'development') {
		// eslint-disable-next-line no-console
		console.error(`Unfortunately, the current environment still requires Plyr Player`);
	}

	return flag;
})();

/**
 * Einen aktiven Player stoppen
 *
 * @param {HTMLElement} elementNextPlayer - der nächste Player der gestartet wird
 */
const stopConcurrentlyPlayer = (elementNextPlayer) => {
	// Stoppe einen ´gespeicherten´ aktiven Player nur, wenn es nicht der
	// selbe Player `elementNextPlayer`.
	if (
		window.activePlayer &&
		Data.get(window.activePlayer, 'uid') !== Data.get(elementNextPlayer, 'uid')
	) {
		EventHandler.trigger(window.activePlayer, `stop${EVENT_KEY}`);
	}
};

/**
 * Event für Player und die Instanz anbinden.
 *
 * @param {Object} instance - Plyr-Instanz
 * @param {HTMLElement} element - Playercontainer
 */
const bindPlayerEvents = (instance, element) => {
	// Event ´play´ (element)
	EventHandler.on(element, `play${EVENT_KEY}`, (ev) => {
		if (Data.get(element, `${DATA_KEY}.initialized`)) {
			Data.get(element, `${DATA_KEY}${API_KEY}`).play();
		}
	});

	// Event ´stop/pause´ (element)
	EventHandler.on(element, `stop${EVENT_KEY}`, (ev) => {
		if (Data.get(element, `${DATA_KEY}.initialized`)) {
			Data.get(element, `${DATA_KEY}${API_KEY}`).pause();
		}
	});

	// Fixes ´Uncaught (in promise) DOMException: play() failed because the
	// user didn't interact with the document first.´
	// If the video has autoplay and is in the viewport, then the error
	// occurs.
	instance.muted = false;

	// Player instance event ´play´ (Plyr).
	instance.on('play', function() {
		// Einen aktiven Player zuerst stoppen.
		stopConcurrentlyPlayer(element);

		Data.set(element, 'isPlaying', true);
		Data.set(element, 'isPaused', false);

		Manipulator.removeClass(element, '-stopped -ended');
		Manipulator.addClass(element, '-playing');

		window.activePlayer = element;
	});

	// Player instance event ´pause´ (Plyr).
	instance.on('pause', function() {
		Data.set(element, 'isPlaying', false);
		Data.set(element, 'isPaused', true);

		Manipulator.removeClass(element, '-playing -ended');
		Manipulator.addClass(element, '-stopped');
	});

	// Player instance event ´ended´ (Plyr).
	instance.on('ended', function() {
		// Aktiven Player in `window` zurücksetzen.
		if (
			window.activePlayer &&
			Data.get(window.activePlayer, 'uid') === Data.get(element, 'uid')
		) {
			window.activePlayer = null;
		}

		Data.set(element, 'isPlaying', false);
		Data.set(element, 'isPaused', true);

		Manipulator.removeClass(element, '-playing -stopped');
		Manipulator.addClass(element, '-ended');
	});
};

/**
 * Einzelnen Audioplayer initialisieren.
 *
 * @param {HTMLElement} element
 * @param {Object} o - Audiooptionen
 * @returns {HTMLElement}
 */
const renderAudio = (element, o) => {
	const audio = SelectorEngine.findOne('audio', element);

	if (!audio) {
		return element;
	}

	const options   = extend({}, DEFAULTS.audio, o);
	const uid            = element.getAttribute('id') || 'player-' + Math.floor(Math.random() * 1000000);
	const playerInstance = new Plyr(audio, options);

	bindPlayerEvents(playerInstance, element);

	Data.set(element, `${DATA_KEY}${API_KEY}`, playerInstance);
	Data.set(element, `${DATA_KEY}.initialized`, true);
	Data.set(element, `uid`, uid);

	Manipulator.addClass(element, '-initialized');

	// Player überwachen (Viewport).
	observerInView.observe(element);

	return element;
};

/**
 * Einzelnen Videoplayer initialisieren.
 *
 * @param {HTMLElement} element
 * @param {Object} o - Videooptionen
 * @returns {HTMLElement}
 */
const renderVideo = (element, o) => {
	const media = SelectorEngine.findOne('[data-player-media]', element);

	if (!media) {
		return element;
	}

	const autoplay  = Manipulator.getDataAttribute(element, 'autoplay');
	const muted     = Manipulator.getDataAttribute(element, 'muted');
	const options   = extend({
		muted   : muted,
		mute   : muted,
		video: {
			autoplay: autoplay,
			muted   : muted,
			mute   : muted
		},
		youtube: {
			autoplay: autoplay,
			muted   : muted,
			mute   : muted
		}
	}, DEFAULTS.video, o);
	const uid       = element.getAttribute('id') || 'player-' + Math.floor(Math.random() * 1000000);
	const iframe    = SelectorEngine.findOne('iframe', media);
	const video    = SelectorEngine.findOne('video', media);
	const isPrivacy = Manipulator.getDataAttribute(element, 'privacy');

	Data.set(element, `${DATA_KEY}.initialized`, false);
	Data.set(element, `${DATA_KEY}.privacy`, isPrivacy);
	Data.set(element, `uid`, uid);

	// Custom-Playbutton.
	// Die Initialisierung eines Players wird nur auf Anforderung des
	// Nutzers vorgenommen.
	const playButton = SelectorEngine.findOne('[data-player-control="play"]', element);

	// Event ´privacy´ (element)
	EventHandler.on(element, 'disable.privacyMode', () => {
		Manipulator.removeDataAttribute(element, 'privacy');

		Data.set(element, `${DATA_KEY}.privacy`, false);

		EventHandler.off(element, 'disable.privacyMode');

		if(autoplay) {
			EventHandler.trigger(playButton, `click${EVENT_KEY}`);
		}
	});

	// Event ´init´ (element)
	EventHandler.on(element, `init${EVENT_KEY}`, () => {
		if (!Data.get(element, `${DATA_KEY}.privacy`)) {
			stopConcurrentlyPlayer(element);

			const playerInstance = new Plyr(media, options);

			bindPlayerEvents(playerInstance, element);

			Data.set(element, `${DATA_KEY}${API_KEY}`, playerInstance);
			Data.set(element, `${DATA_KEY}.initialized`, true);

			Manipulator.addClass(element, '-initialized');

			if(autoplay) {
				playerInstance.muted = 1;
				playerInstance.muted = true;
			}

			setTimeout(function() {
				playerInstance.play();
			}, 500);
		}
	});

	if(playButton) {
		EventHandler.on(playButton, `click${EVENT_KEY}`, (ev) => {
			ev.preventDefault();
			ev.stopPropagation();

			if (Data.get(element, `${DATA_KEY}.initialized`)) {
				EventHandler.trigger(element, `play${EVENT_KEY}`);
			} else {
				// Liegt ein iframe vor, dann muss das Attribut `src` mit dem
				// Wert von `data-src` gesetzt werden.
				if (iframe) {
					const dataSrc = Manipulator.getDataAttribute(iframe, 'src');

					iframe.setAttribute('src', dataSrc);

					Manipulator.removeDataAttribute(iframe, 'src');
				}

				// Player initialisieren > play().
				EventHandler.trigger(element, `init${EVENT_KEY}`);
			}

			// playButton.remove();
		});
	}

	// Player überwachen (Viewport).
	observerInView.observe(element);

	return element;
};

// -------
// Public
// -------

/**
 * Alle verfügbaren Audioplayer initialisieren.
 *
 * @param {HTMLElement} [element=null] - standardmäßig wird nach `[data-player="audio"]` gesucht.
 * @param {Object} [o={}] - Audiooptionen
 * @returns {Array} - Feld aller initialisierten Player
 */
const setupAudio = (element = null, o = {}) => {
	let group = [];

	if (element) {
		group = renderAudio(element, o);
	} else {
		const collection = SelectorEngine.find('[data-player="audio"]');

		for (const element of collection) {
			group.push(renderAudio(element, o));
		}
	}

	return group;
};

/**
 * Alle verfügbaren Videoplayer initialisieren.
 *
 * @param {HTMLElement} [element=null] - standardmäßig wird nach `[data-player="video"]` gesucht.
 * @param {Object} [o={}] - Videooptionen
 * @returns {Array} - Feld aller initialisierten Player
 */
const setupVideo = (element = null, o = {}) => {
	let group;

	if (isElement(element)) {
		group = renderVideo(element,o );
	} else {
		const collection = SelectorEngine.find('[data-player="video"]');

		group = [];

		for (const element of collection) {
			group.push(renderVideo(element, o));
		}
	}

	return group;
};

/**
 * Alle vorhandenen ´Audio´ und ´Video´ initialisieren.
 *
 * @param {Object} [o={audio: {}, video: {}}] - Audio- und Videooptionen
 * @returns {{audio: Array, video: Array}}
 */
const init = (o = {}) => {
	const _o = extend({}, {
		audio: {},
		video: {}
	}, o);

	return {
		audio : setupAudio(null, _o.audio),
		video : setupVideo(null, _o.video)
	};
};

// Export
export default {
	init : (window.depCheckPlayer ? init : noop),
	audio: (window.depCheckPlayer ? setupAudio : noop),
	video: (window.depCheckPlayer ? setupVideo : noop)
};
