/**
 * ´I18n´
 */

const de = {
	// Aria
	ariaLabelSubmenu       : 'Untermenü zu %s',
	ariaLabelSubmenuTrigger: 'Öffne/schließe Untermenü zu %s',
	// Eintrag
	itemNext: 'nächster Eintrag',
	itemPrev: 'vorheriger Eintrag',
	itemGoTo: 'gehe zu Eintrag {{index}}',
	// Bild
	imageNext: 'nächstes Bild',
	imagePrev: 'vorheriges Bild',
	imageZoom: 'Bild vergrößern',
	// Slider
	slideNext: 'nächster Slide',
	slidePrev: 'vorheriger Slide',
	slideGoTo: 'gehe zu Slide {{index}}',
	// Modal
	modalClose: 'Overlay schließen',
	modalOpen : 'Overlay öffnen',
	// Begrifflichkeiten
	close      : 'schließen',
	closeEsc   : 'per ESC-Taste schließen',
	download   : 'herunterladen',
	error      : 'Ein Fehler ist aufgetreten',
	iterateZoom: 'Iterieen sie den Zoom',
	next       : 'weiter',
	prev       : 'zurück',
	searchTerm : 'Suchbegriff',
	selected   : 'ausgewählt',
	selection  : 'Auswahl'
};

const en = {
	// Aria
	ariaLabelSubmenu       : 'Submenu for %s',
	ariaLabelSubmenuTrigger: 'Open/close submenu for %s',
	// Eintrag
	itemNext: 'next item',
	itemPrev: 'previous item',
	itemGoTo: 'go to item {{index}}',
	// Bild
	imageNext: 'next image',
	imagePrev: 'previous image',
	imageZoom: 'zoom image',
	// Slider
	slideNext: 'next slide',
	slidePrev: 'previous slide',
	slideGoTo: 'go to slide {{index}}',
	// Modal
	modalClose: 'close overlay',
	modalOpen : 'open overlay',
	// Begrifflichkeiten
	close      : 'close',
	closeEsc   : 'close with the ESC key',
	download   : 'download',
	error      : 'nn error has occurred',
	iterateZoom: 'iterate zoom',
	next       : 'next',
	prev       : 'vorherig',
	searchTerm : 'Search term',
	selected   : 'selected',
	selection  : 'Selection'
};

export default {
	de,
	en
};
