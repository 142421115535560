/**
 * Shared: Components / Form / Tools > Toggle read only
 *
 * @copyright 2023 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

import {
	execute,
	getElementFromSelector,
	noop,
	normalizeAttributeValue
} from '../../..//utils/index';

import SelectorEngine from '../../../dom/selector-engine';
import EventHandler from '../../../dom/event-handler';
import BaseClass from '../../../utils/base-class';

/**
 * @type {string}
 */
const NAME = 'toggle-readonly';

/**
 * @type {string}
 */
const VERSION = '1.0.0';

/**
 *
 * @type {Object}
 */
const DEFAULT = {
	placeholder: '',
	required   : false,
	clearValue : false,
	onInit     : noop, // (event) => { console.log('onInit', event); },
	onSet      : noop, // (event) => { console.log('onSet', event); },
	onUnset    : noop // (event) => { console.log('onUnset', event); }
};

/**
 *  Class
 */
class ToggleReadOnly extends BaseClass {
	/**
	 * @param {HTMLElement|Node} [element=null]
	 * @param {Object} [config={}]
	 */
	constructor(element = null, config = {}) {
		if (!element) {
			return;
		}

		// Ist Element schon eine Instanz von `Nav`?
		const testInst = ToggleReadOnly.getInstance(element);

		if (testInst && testInst['_config'] !== undefined) {
			return testInst;
		}

		super(element, config);

		// Eingavefeld vom Attribut ´data-target´  des Toggle (`this._element`) holen.
		this.target = getElementFromSelector(this._element);

		if (this.target) {
			this._setup();
		}
	}

	/**
	 *
	 * @param flag
	 */
	readOnly(flag = true) {
		if (flag) {
			this.target.setAttribute('readonly', 'true');
			this.target.removeAttribute('required');
			this.target.removeAttribute('placeholder');

			// this.currentValue = this.target.value.trim();

			if (this._config.clearValue) {
				this.target.value = '';
			}

			const eventSet = EventHandler.trigger(this._element, `set`, this.eventParams);

			execute(this._config.onSet, eventSet);
		} else {
			this.target.removeAttribute('readonly');

			// this.target.value = this.currentValue;

			if (this._config.required) {
				this.target.setAttribute('required', 'true');
			}

			if (this.placeholder) {
				this.target.setAttribute('placeholder', this.placeholder);
			}

			const eventUnset = EventHandler.trigger(this._element, `unset`, this.eventParams);

			execute(this._config.onUnset, eventUnset);
		}
	}

	/**
	 * Initialisierung.
	 *
	 * @private
	 */
	_setup() {
		this.currentValue = this.target.value.trim();

		// Option ´required´ aktualisieren.
		// Eingabefeld kann bei ´Freigabe´ mit dem Attribut ´required´ versehen werden.
		if (this._element.dataset.required !== undefined) {
			this._config.required = normalizeAttributeValue(this._element.dataset.useRequired ?? false);
		}

		//
		// Festlegung des Toggletyps bzgl. Anbindung von Events.
		// Ein Toggle kann eine Checkbox oder auch eine Option eines Select sein.
		//

		this.toggle     = null;
		this.toggleType = this._element.getAttribute('type');

		const tagName = this._element.tagName.toLowerCase();

		if (
			tagName === 'input' &&
			['checkbox', 'radio'].indexOf(this.toggleType) >= 0
		) {
			// Checkbox ...
			this.toggle      = this._element;
			this.toggleType = 'input';
			this.toggleEvent = 'click';

			// Eingabefeld kann bei ´Freigabe´ mit dem Attribut ´plceholder´ versehen werden.
			this.placeholder = this.toggle.dataset.placeholder ?? this._config.placeholder;
		} else if (tagName === 'option') {
			// Select ...
			const select = SelectorEngine.parents(this._element, 'select');

			if (select[0]) {
				this.toggle      = select[0];
				this.toggleType  = 'select';
				this.toggleEvent = 'change';
			}
		}

		if (this.toggle) {
			this._addListeners();
		}
	}

	/**
	 * Events anbinden.
	 * @private
	 */
	_addListeners() {
		this.eventParams = {
			target: this.target,
			toggle: this.toggle
		};

		EventHandler.on(this.toggle, `handle${this.constructor.EVENT_KEY}`, this._handler.bind(this));

		EventHandler.on(this.toggle, `${this.toggleEvent}${this.constructor.EVENT_KEY}`, (event) => {
			EventHandler.trigger(this.toggle, `handle${this.constructor.EVENT_KEY}`);
		});

		EventHandler.trigger(this.toggle, `handle${this.constructor.EVENT_KEY}`);

		const eventInit = EventHandler.trigger(this._element, `init`, this.eventParams);

		execute(this._config.onInit, eventInit);
	}

	/**
	 * Handler-Funktion für das Setzen von Read-only.
	 * @private
	 */
	_handler() {
		let isReadOnly = true;

		if (this.toggleType === 'input') {
			isReadOnly = !this.toggle.checked;
		}

		if (this.toggleType === 'select') {
			let options = this.toggle.selectedOptions;

			if (options.length > 0) {
				let optionFound = false;

				for (const option of options) {
					if (!optionFound && option.dataset.toggleReadonly !== undefined) {
						optionFound = true;
						isReadOnly  = false;

						// Eingabefeld kann bei ´Freigabe´ mit dem Attribut ´plceholder´ der ausgewählten Option
						// versehen werden.
						this.placeholder = option.dataset.placeholder ?? this._config.placeholder;
					}
				}
			}
		}

		this.readOnly(isReadOnly);
	}

	// Static ------------------------------------------------------------------

	// Getters -----------------------------------------------------------------

	/**
	 * @returns {string}
	 * @constructor
	 */
	static get VERSION() {
		return VERSION;
	}

	/**
	 * @returns {Object}
	 * @constructor
	 */
	static get Default() {
		return DEFAULT;
	}

	/**
	 * @returns {string}
	 * @constructor
	 */
	static get NAME() {
		return NAME;
	}

	/**
	 * @returns {string}
	 * @constructor
	 */
	static get DATA_KEY() {
		return `ifab.${this.NAME}`;
	}

	/**
	 * @returns {string}
	 * @constructor
	 */
	static get EVENT_KEY() {
		return `.${this.DATA_KEY}`;
	}
}

// Export
export default ToggleReadOnly;
