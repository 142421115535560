/**
 * Shared: Utils > Lazy load
 *
 * @copyright 2023 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

import {noop}       from './index';
import {getFeature} from './support/feature';

import SelectorEngine from '../dom/selector-engine';
import Manipulator    from '../dom/manipulator';

// -------
// Private
// -------

/**
 * Iframes
 */
const handleIframes = () => {
	const elements = SelectorEngine.find('iframe[loading="lazy"][data-src]');

	for (const element of elements) {
		const dataSrc = Manipulator.getDataAttribute(element, 'src');

		element.setAttribute('src', dataSrc);

		Manipulator.removeDataAttribute(element, 'src');
		Manipulator.removeClass(element, 'lazyload');
	}
};

/**
 * Bilder
 */
const handleImages = () => {
	const elements = SelectorEngine.find('img[loading="lazy"][data-src]');

	for (const element of elements) {
		const picture    = SelectorEngine.parents(element, 'picture')[0];
		const dataSrc    = Manipulator.getDataAttribute(element, 'src');
		const dataSrcSet = Manipulator.getDataAttribute(element, 'srcset');
		const dataSizes  = Manipulator.getDataAttribute(element, 'sizes');

		element.setAttribute('src', dataSrc);
		Manipulator.removeDataAttribute(element, 'src');

		if(dataSizes) {
			element.setAttribute('sizes', dataSizes);

			Manipulator.removeDataAttribute(element, 'sizes');
		}

		if(dataSrcSet){
			element.setAttribute('srcset', dataSrcSet);

			Manipulator.removeDataAttribute(element, 'srcset');
		}

		Manipulator.removeClass(element, 'lazyload');

		// Picture-Tag
		if(picture) {
			const sources = SelectorEngine.find('source', picture);

			for (const source of sources) {
				const dataSrcSet = Manipulator.getDataAttribute(source, 'srcset');

				if(dataSrcSet) {
					source.setAttribute('srcset', dataSrcSet);
					Manipulator.removeDataAttribute(source, 'srcset');
				}

				Manipulator.removeClass(source, 'lazyload');
			}
		}
	}
};

// ------
// Public
// ------

/**
 * Export
 *
 * @param {Function} fallback
 */
export default (fallback = noop) =>  {
	const fS = getFeature();

	if (fS.lazyLoading) {
		handleImages();
		handleIframes();
	} else {
		fallback();
	}
};
