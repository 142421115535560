/**
 * Shared: Components / Gmap > Google maps
 *
 * @copyright 2023 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

import {
	execute,
	extend,
	getUid,
	noop
} from '../../utils/index';
import {
	isString,
	isObject
} from '../../utils/is';

import SelectorEngine from '../../dom/selector-engine';
import Manipulator    from '../../dom/manipulator';
import EventHandler   from '../../dom/event-handler';

import BaseClass from '../../utils/base-class';

/**
 * @type {string}
 */
const NAME = 'googlemaps';

/**
 * @type {string}
 */
const VERSION = '1.0.0';

/**
 *
 * @type {Object}
 */
const DEFAULT = {
	autofit        : true,
	map            : {
		center           : {
			lat: 51.1099714,
			lng: 10.1877937
		},
		typeId           : '',
		typeControl      : true,
		panControl       : true,
		scaleControl     : true,
		streetViewControl: false,
		zoom             : 12
	},
	marker         : {
		anchorPoint: null,
		animation  : null,
		clickable  : false,
		crossOnDrag: true,
		cursor     : 'pointer',
		draggable  : false,
		icon       : '',
		label      : null,
		opacity    : 1,
		title      : '',
		visible    : true
	},
	icons          : [],
	onInit         : noop, // (event) => { console.log('onDragend', event); },
	onClear        : noop, // (event) => { console.log('onDragend', event); },
	onDragend      : noop, // (event) => { console.log('onDragend', event); },
	onZoomChanged  : noop, // (zoom, event) => { console.log('onZoomChanged', zoom, event); },
	onMarkerAdded  : noop, // (marker, event) => { console.log('onMarkerAdded', marker, event); },
	onMarkerRemoved: noop, // (event) => { console.log('onMarkerRemoved', event); },
	onMarkerClick  : noop // (marker, event) => { console.log('onMarkerClick', marker, event); }
};

/**
 *  Class
 */
class GoogleMaps extends BaseClass {
	/**
	 * @param {HTMLElement|Node} [element=null]
	 * @param {Object} [config={}]
	 */
	constructor(element = null, config = {}) {
		super(element, config);

		// Referenz zur Google-Maps-API.
		this._GM = (window.google !== undefined && window.google.maps !== undefined) ? window.google.maps : null;

		// Keine Google-API, dann Abbruch.
		if (!this._GM) {
			return;
		}

		this._mapContainer = Manipulator.elementPrepend('<div class="gmap-card"/>', this._element);
		this._map          = null;
		this._marker       = [];
		this._runtimeData  = {
			markers: {}
		};

		// Instanz ´Info window´
		this._IW  = new this._GM.InfoWindow();

		this._mergeMapDataAttributes();
		this._setupMap();
	}

	addMarker(data) {
		let _o = extend({}, this._config.marker, data);

		let marker = null;

		// Marker nur setzen wenn auch Koordinaten vorhanden sind.
		if (_o.lat && _o.lng) {
			//
			// Markerdaten zusammenstellen.
			//

			// ... Map
			_o.map = this._map;

			// ... Position
			_o.position = {
				lat: _o.lat,
				lng: _o.lng
			};

			delete _o.lat;
			delete _o.lng;

			// ... ID
			if(_o.id === undefined || !_o.id) {
				_o.id = getUid('marker');
			}

			// ... Icon
			if (_o.icon && isString(_o.icon) && this._config.icons[_o.icon]) {
				_o.icon = this._config.icons[_o.icons];
			}

			// ... Label
			// if (data.label) {
			//
			// }

			//
			// Marker bereitstellen.
			//

			// ... Instanz
			marker = new this._GM.Marker(_o);

			// ... Infofenster
			if (_o.infoContent || _o.clickable) {
				if (_o.infoContent) {
					marker.addListener('click', () => {
						this._IW.setContent(`<div class="gmap-marker">${marker.infoContent}</div>`);
						this._IW.open(this._map, marker);

						execute(this._config.onMarkerClick, marker, this._events.markerClick);
					});
				} else {
					marker.addListener('click', () => {
						execute(this._config.onMarkerClick, marker, this._events.markerClick);
					});
				}
			} else {
				_o.cursor = 'default';
			}

			// ... speichern
			this._runtimeData.markers[_o.id] = marker;

			execute(this._config.onMarkerAdded, marker, this._events.markerAdded);
		}

		return marker;
	}

	/**
	 * Marker entfernen.
	 *
	 * @param {(String|Object)} m - Marker-ID oder -Objekt.
	 * @access public
	 */
	removeMarker(m) {
		let marker;

		// Marker bestimmen.
		if (isString(m) && this.runtimeData.markers[m]) {
			marker = this.runtimeData.markers[m];
		} else if (isObject(m)) {
			marker = m;
		} else {
			marker = null;
		}

		if (marker) {
			// if (this.Oms || this.Mc) {
			// 	if (this.Oms) {
			// 		this.Oms.removeMarker(mrk);
			// 	}
			//
			// 	// MarkerClusterer erneuern?
			// 	if (this.Mc) {
			// 		this.Mc.removeMarker(mrk);
			// 	}
			// } else {
			marker.setMap(null);
			// }

			// Gespeicherten Marker entfernen.
			delete this.runtimeData.markers[m];

			// Autofit der Map.
			if (this._config.autofit) {
				this.autofit();
			}

			// Marker-Removed-Event triggern.
			execute(this._config.onMarkerRemoved, this._events.markerRemoved);
		}
	}

	/**
	 * Aktive Marker zurückgeben.
	 *
	 * @returns {Array}
	 */
	getMarkers() {
		return Object.values(this._runtimeData.markers);
	}

	/**
	 * Aktive Marker zurückgeben.
	 *
	 * @returns {Array}
	 */
	getActiveMarkers() {
		const markers = this.getMarkers();

		return markers.filter(marker => marker.visible === true);
	}

	/**
	 * Aktive Marker zurückgeben.
	 *
	 * @returns {Array}
	 */
	getInactiveMarkers() {
		const markers = this.getMarkers();

		return markers.filter(marker => marker.visible === false);
	}

	/**
	 * Koordinatenbereich aller Marker kalkulieren.
	 *
	 * @returns {Object|Null}
	 */
	getMarkerBounds() {
		const markers = this.getActiveMarkers();
		let bounds    = null;

		if (markers.length) {
			bounds = new this._GM.LatLngBounds();

			for (const marker of markers) {
				bounds.extend(marker.getPosition());
			}
		}

		return bounds;
	}

	/**
	 * Alle Marker holen die innerhalb des Anzeigebereiches der Map liegen.
	 *
	 * @since 1.0.0
	 * @access public
	 *
	 * @returns {Array}
	 */
	getMarkerInBounds() {
		const markers    = this.getActiveMarkers();
		const bounds     = this.Map.getBounds();
		const collection = [];

		if (markers.length) {
			for (const marker of markers) {
				if (bounds.contains(marker.getPosition())) {
					collection.push(marker);
				}
			}
		}

		return collection;
	}

	clear() {
		const keys = Object.keys(this._runtimeData.markers);

		for (const key of keys) {
			this._runtimeData.markers[key].setMap(null);
		}

		// if (this.Mc)
		// {
		// 	this.Mc.clearMarkers();
		// }
		//
		// if (this.Oms)
		// {
		// 	this.Oms.clearMarkers();
		// }

		this._runtimeData.markers = {};

		// callUserEv.apply(this, ['onCleared']);
	}

	/**
	 * Autofit der Map setzen.
	 *
	 * Markeranzahl > 1, Map auf alle Marker zoomen.
	 * Markeranzahl = 1, Map auf Marker zentrieren.
	 */
	autofit() {
		const markers = this.getActiveMarkers();
		const bounds  = this.getMarkerBounds();

		if (markers.length > 0 && bounds) {
			if (markers.length > 1) {
				this._map.fitBounds(bounds);
			} else {
				this._map.panTo(markers[0].getPosition());
			}

			this._updateRuntimeData();
		}
	}

	// Private -----------------------------------------------------------------

	_setupMap() {
		// Resize Observer
		this.resizeObserver = new ResizeObserver(() => {
			if (this._map && this._runtimeData.center) {
				if (this._config.autofit) {
					this.autofit();
				} else {
					this._map.panTo(this._runtimeData.center);

					this._updateRuntimeData();
				}
			}
		});

		// Instanz ´Googlemap´
		this._map = new this._GM.Map(this._mapContainer, this._config.map);

		// Map-Events anbinden.
		this._bindEvents();

		// Marker hinzufügen.
		this._readMarkers();

		// Laufzeitdaten aktualisieren.
		this._updateRuntimeData();

		// Resize Observer initialisieren.
		this.resizeObserver.observe(this._element);

		// Init-Event triggern.
		execute(this._config.onInit, this._events.init);
	}

	/**
	 * Events für die Map initialisieren.
	 * @private
	 */
	_bindEvents() {
		this._events = {
			init    : EventHandler.trigger(this._mapContainer, `onInit`, {
				map        : this._map,
				runtimeData: this._runtimeData
			}),
			clear    : EventHandler.trigger(this._mapContainer, `onClear`, {
				map        : this._map,
				runtimeData: this._runtimeData
			}),
			dragend    : EventHandler.trigger(this._mapContainer, `onDragend`, {
				map        : this._map,
				runtimeData: this._runtimeData
			}),
			zoomChanged: EventHandler.trigger(this._mapContainer, `onZoomChanged`, {
				map        : this._map,
				runtimeData: this._runtimeData
			}),
			markerClick: EventHandler.trigger(this._mapContainer, `onMarkerClick`, {
				map        : this._map,
				runtimeData: this._runtimeData
			}),
			markerAdded: EventHandler.trigger(this._mapContainer, `onMarkerAdded`, {
				map        : this._map,
				runtimeData: this._runtimeData
			}),
			markerRemoved: EventHandler.trigger(this._mapContainer, `onMarkerRemoved`, {
				map        : this._map,
				runtimeData: this._runtimeData
			})
		};

		//
		// Map-Event ´Dragend´
		//

		const mapDragendCallback = () => {
			this._updateRuntimeData();
			execute(this._config.onDragend, this._events.dragend);
		};

		this._map.addListener('dragend', mapDragendCallback);

		//
		// Map-Event ´Zoom changed´
		//

		const mapZoomChangedCallback = () => {
			this._updateRuntimeData();

			execute(this._config.onZoomChanged, this._runtimeData.zoom, this._events.zoomChanged);
		};

		this._map.addListener('zoom_changed', mapZoomChangedCallback);
	}

	/**
	 * @private
	 */
	_readMarkers() {
		// Marker vorhanden? Ja, dann integrieren.
		const markerScriptTpl = SelectorEngine.find('script[data-marker]', this._element);

		if(markerScriptTpl.length) {
			for (const marker of markerScriptTpl) {
				const data = Manipulator.getDataAttributes(marker);

				delete data.marker;

				if (data.infoContent) {
					if (
						data.infoContent.indexOf('#') === 0
						|| data.infoContent.indexOf('.') === 0
					) {
						const target = SelectorEngine.findOne(data.infoContent);

						data.infoContent = (target) ? target.innerHTML : '';
					}
				} else {
					data.infoContent = marker.innerHTML;
				}

				this.addMarker(data);

				marker.remove();
			}
		}
	}

	/**
	 * Mapkonfiguration mit den Data-Attributen des Gmap-Containers
	 * aktualisieren.
	 * @private
	 */
	_mergeMapDataAttributes() {
		const allowedKeys = Object.keys(this._config.map);
		const attributes  = Manipulator.getDataAttributes(this._element);

		for (const key of Object.keys(attributes)) {
			if (allowedKeys.indexOf(key) >= 0) {
				if (key === 'center') {
					const coordinates = attributes[key].split(',');

					this._config.map.center.lat = parseFloat(coordinates[0]);
					this._config.map.center.lng = parseFloat(coordinates[1]);
				} else {
					this._config.map[key] = attributes[key];
				}
			} else {
				if (key === 'lat' || key === 'lng') {
					this._config.map.center[key] = parseFloat(attributes[key]);
				}
			}
		}
	}

	/**
	 * Laufzeitdaten der Map speichern.
	 * @private
	 */
	_updateRuntimeData() {
		if (this._map) {
			this._runtimeData.center = this._map.getCenter();
			this._runtimeData.lat    = this._map.getCenter().lat();
			this._runtimeData.lng    = this._map.getCenter().lng();
			this._runtimeData.zoom   = this._map.getZoom();
		}
	}

	// Static ------------------------------------------------------------------

	/**
	 * @param {string} name
	 * @returns {string}
	 */
	static eventName(name) {
		return `${name}${this.EVENT_KEY}`;
	}

	// Getters -----------------------------------------------------------------

	/**
	 * @returns {string}
	 * @constructor
	 */
	static get VERSION() {
		return VERSION;
	}

	/**
	 * @returns {Object}
	 * @constructor
	 */
	static get Default() {
		return DEFAULT;
	}

	/**
	 * @returns {string}
	 * @constructor
	 */
	static get NAME() {
		return NAME;
	}

	/**
	 * @returns {string}
	 * @constructor
	 */
	static get DATA_KEY() {
		return `ifab.${this.NAME}`;
	}

	/**
	 * @returns {string}
	 * @constructor
	 */
	static get EVENT_KEY() {
		return `.${this.DATA_KEY}`;
	}
}

// Export
export default GoogleMaps;
