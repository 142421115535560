/**
 * Initial
 */

import {setRootVar} from '../../shared/utils/index';
import {focusVisible} from '../../shared/utils/focus-visible';
import {scrollTop} from '../../shared/utils/scroll';
import {loadJavascript} from "../../shared/utils/load";
import lazyLoad from '../../shared/utils/lazy-load';

import SelectorEngine from '../../shared/dom/selector-engine';
import Manipulator from '../../shared/dom/manipulator';
import EventHandler from '../../shared/dom/event-handler';

/**
 * Monitor ´document.documentElement´ changes.
 *
 * @type {MutationObserver}
 */
const observerDocumentMutation = new MutationObserver((mutations) => {
	EventHandler.trigger(document.documentElement, 'ifab.documentMutation', {
		mutations
	});
});

const observerDocHeight = () => {
	const calc = () => {
		setRootVar(`${window.pageInstance.prefix}vh`, `${window.innerHeight/100}px`);
	};

	window.addEventListener('resize', calc);
	window.addEventListener('orientationchange', calc);

	calc();
};

/**
 * Accessibility (`:focus` erst bei Tastaturnavigation anzeigen.)
 */
const handleFocusableElements = () => {
	const collection = SelectorEngine.find('input, textarea, select, label, a, button');

	for (const element of collection) {
		focusVisible.observe(element);
	}
};

const bindLazyload = () => {
	// Fehlerbehandlung von ´fehlenden Bildern´ anbinden.
	const images = SelectorEngine.find('img');

	for (const image of images) {
		EventHandler.on(image, 'error', (event) => {
			Manipulator.addClass(event.target, 'is-faulty');
		});
	}

	// Lazyload initialisieren.
	lazyLoad(function () {
		const lazyElements = SelectorEngine.find('.lazyload[data-src]');

		if (lazyElements) {
			window.lazySizesConfig = window.lazySizesConfig || {};
			window.lazySizesConfig.loadMode = 1;

			loadJavascript(`${window.pageInstance.path.dist}vendors/lazysizes/lazysizes.min.js`, {
				async: true,
				// callback: () => {},
				id: 'lazysizes'
			});
		}
	});
};

// -------
// Public
// -------

const initial = () => {
	observerDocumentMutation.observe(document.documentElement, {
		attributes: true
	});

	observerDocHeight();
	bindLazyload();
	handleFocusableElements();

	// ´History back´ - Buttons
	EventHandler.on(document.body, 'click', 'button[data-history-back]', (event) => {
		event.preventDefault();
		event.stopPropagation();

		history.back();
	});

	// ´Scroll top´ - Elwmente
	EventHandler.on(document, 'click', '[data-scroll-top]', (event) => {
		// preventDefault only for <a> elements (which change the URL) not inside the collapsible element
		if (
			event.target.tagName.toLowerCase() === 'a' ||
			(event.delegateTarget && event.delegateTarget.tagName.toLowerCase() === 'a')
		) {
			event.preventDefault();
		}

		scrollTop().then(element => {
			// `element` = zu ihm wird gescrollt
			// Do nothing ...
		});
	});

	// ...globalen ´Scroll top´-Button integrieren
	const scrollTopTrigger = SelectorEngine.findOne('button[data-scroll-top="global"]');

	if (scrollTopTrigger) {
		document.addEventListener('scroll', function () {
			requestAnimationFrame(() => {
				if (window.scrollY > window.innerHeight) {
					Manipulator.addClass(scrollTopTrigger, '-show');
				} else {
					Manipulator.removeClass(scrollTopTrigger, '-show');
				}
			});
		});
	}
};

// Export
export default initial;
